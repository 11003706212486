import { render, staticRenderFns } from "./Responsible.vue?vue&type=template&id=756c69d7&scoped=true"
import script from "./Responsible.vue?vue&type=script&lang=js"
export * from "./Responsible.vue?vue&type=script&lang=js"
import style0 from "./index.css?vue&type=style&index=0&id=756c69d7&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "756c69d7",
  null
  
)

export default component.exports