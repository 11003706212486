<template>
  <div>
    <section class="selfcare-title d-flex">
      <div>
        <a class="btn btn-link btn-sm" style="color: #000" @click="goBack()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M7.97496 4.94165L2.91663 9.99998L7.97496 15.0583"
              stroke="var(--text-color)"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.0834 10H3.05835"
              stroke="var(--text-color)"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
    </section>

    <div class="container_wrapper" style="padding: 0px 20px 0 20px">
      <h5>Responsible Gaming</h5>
      <p>
        {{ $t("commitmentToResponsibleGaming") }}
      </p>
      <h6>{{ $t("PROTECTIONMINORS") }}</h6>
      <p>
        {{ $t("ageRestrictionPolicy") }}
      </p>
      <p>
        {{ $t("recommendationForParentalControls") }}
      </p>

      <h6>{{ $t("GAMINGADDICTION") }}</h6>

      <p>
        {{ $t("gamblingPublicHealthConcern") }}
      </p>
      <p>
        {{ $t("responsibleGamingPolicyFramework") }}
      </p>
      <p>
        {{ $t("limitSettingTools") }}
      </p>

      <h6>{{ $t("selfControl") }}</h6>

      <p>
        {{ $t("compulsiveGamblingTest") }}
      </p>
      <p>
        {{ $t("tipsToControGambling") }}
      </p>
      <ol>
        <li>{{ $t("gamblingControlTips.1") }}</li>
        <li>{{ $t("gamblingControlTips.2") }}</li>
        <li>{{ $t("gamblingControlTips.3") }}</li>
        <li>{{ $t("gamblingControlTips.4") }}</li>
        <li>{{ $t("gamblingControlTips.5") }}</li>
        <li>{{ $t("gamblingControlTips.6") }}</li>
      </ol>

      <h6>{{ $t("selfExclusion") }}</h6>

      <p>
        {{ $t("voluntarySelfExclusion") }}
      </p>
      <p>
        {{ $t("howToRequestSelfExclusion") }}
      </p>
      <p>
        {{ $t("contactingMentalHealthProfessionals") }}
      </p>
      <p>
        {{ $t("selfExclusionConsequences") }}
      </p>

      <h6>{{ $t("operatorAction") }}</h6>

      <p>
        {{ $t("companyImposedExclusion") }}
      </p>
      <p>
        {{ $t("denyingReactivationRequests") }}
      </p>
      <p>
        {{ $t("supportForProblemGambling") }}
      </p>

      <bottom-navigation active-bets="0" page="login"></bottom-navigation>
    </div>
    <Footer />
  </div>
</template>

<script>
import BottomNavigation from "@/components/BottomNavigation";
import Footer from "../../../components/Footer.vue";

export default {
  name: "Responsible",
  components: {
    BottomNavigation,
    Footer,
  },
  mounted: function () {
    this.$store.dispatch("setCurrentPage", "responsible");
    // this.reloadProfile();
  },
};
</script>

<style src="./index.css" scoped>
ul {
  padding: 0 0 0 20px;
}
</style>
